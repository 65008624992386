import { createApp } from "vue/dist/vue.esm-bundler";
import axios from "axios";
import VueAxios from "vue-axios";
import EventBus from "../vue/EventBus.js";
import * as Sentry from '../sentry.js';

$(function () {
  $("a[data-product-id]").on("click", function () {
    var product_id = $(this).data("product-id");
    let item = dataLayerItems.find((obj) => {
      return obj.product_id == product_id;
    });

    if (item) {
      item.index = 1;
      item.quantity = 1;

      dataLayer.push({ event: "select_item", ...{ ecommerce: { items: item } } });
    }
  });

  //function for favorite + selected
  function _doListRequest(list, button) {
    if (button.hasClass("btn-inactive")) {
      button.removeClass("btn-inactive").addClass("btn-active");
      $.ajax({
        url: "/ajax/" + list + "/add/" + button.data("product_id"),
        type: "POST",
        data: {
          quantity: 1,
        },
        success: function (result) {
          button.removeClass("btn-inactive").addClass("btn-active");
        },
        error: function (error) {
          button.removeClass("btn-active").addClass("btn-inactive");
        },
      });
    } else {
      button.removeClass("btn-active").addClass("btn-inactive");
      $.ajax({
        url: "/ajax/" + list + "/remove/" + button.data("product_id"),
        type: "DELETE",
        success: function (result) {
          button.removeClass("btn-active").addClass("btn-inactive");
        },
        error: function (error) {
          button.removeClass("btn-inactive").addClass("btn-active");
        },
      });
    }
  }

  //favorite button
  $(".favorite-selected-group .btn-favorite").on("click", function (e) {
    e.preventDefault();

    _doListRequest("favorite", $(this));
  });

  //selected button
  $(".favorite-selected-group .btn-selected").on("click", function (e) {
    e.preventDefault();

    _doListRequest("selected", $(this));
  });

  doOnResize();

  $(window).resize(function () {
    doOnResize();
  });

  //rotate refresh icon on link click
  $(".refresh-stock").on("click", function () {
    $(".refresh-stock .fa").addClass("rotate");
  });

  //fix on click on overlay
  $(".product-grid .product .card .overlay").on("click", function () {
    window.location = $(this).parent().find("a").first().attr("href");
  });

  //cart (plus/min/remove) buttons/inputs
  $(".cart-group:not(.vue) .btn-minus").on("click", function (e) {
    e.preventDefault();
    var input = $(this).parents(".cart-group").find("input");
    var quantity = parseInt(input.val()) - input.data("steps");

    if (quantity < 1) {
      input.val(0);
    } else {
      input.val(quantity);
    }
  });

  $(".cart-group:not(.vue) .btn-plus").on("click", function (e) {
    e.preventDefault();
    var input = $(this).parents(".cart-group").find("input");
    var quantity = parseInt(input.val()) + input.data("steps");

    input.val(quantity);
  });

  $(".cart-group:not(.vue) input").on("focus", function (e) {
    if ($(this).val() == 0) {
      this.select();
    }
  });

  $(".cart-group:not(.vue) input").on("change", function (e) {
    e.preventDefault();
    var quantity = parseInt($(this).val());

    $(this).val(
      Math.ceil(quantity / $(this).data("steps")) * $(this).data("steps")
    );
  });

  $(".cart-group:not(.vue)").on("submit", function (e) {
    e.preventDefault();

    var input = $(this).find("input");
    var product_id = input.data("product_id");
    var last_quantity = input.attr("data-last-quantity");
    var current_quantity = input.val();

    if (last_quantity == current_quantity) {
      return;
    }

    input.attr("data-last-quantity", current_quantity);

    $.ajax({
      url: "/ajax/cart/add/" + product_id,
      type: "POST",
      data: {
        quantity: input.val(),
      },
      success: function (result) {
        // TODO headercart.getData();
        EventBus.emit("update-headercart");

        let item = dataLayerItems.find((obj) => {
          return obj.product_id == product_id;
        });

        if (!item) {
          item = dataLayerOtherItems.find((obj) => {
            return obj.product_id == product_id;
          });
        }

        if (item) {
          item.index = 1;

          var dataLayerEvent;
          if (current_quantity > last_quantity) {
            dataLayerEvent = "add_to_cart";
            item.quantity = current_quantity - last_quantity;
          } else {
            dataLayerEvent = "remove_from_cart";
            item.quantity = last_quantity - current_quantity;
          }

          dataLayer.push({
            event: dataLayerEvent,
            ...{ ecommerce: { items: item } },
          });
        }

        if (window.innerWidth < 992) {
          $("#added_to_cart_message")
            .text(
              $("#cart-message-" + product_id)
                .text()
                .trim()
            )
            .addClass("d-flex")
            .fadeIn(500, function () {
              var alert = $(this);
              setTimeout(function () {
                alert.text(
                  SUBTOTAL_TRANSLATION + ": " + $("#sub-total-price").text()
                );
              }, 2000);

              setTimeout(function () {
                alert.removeClass("d-flex");
              }, 3250);
            });
        }
      },
    });
  });

  //load only when container is on the page
  if ($("#headercart").length) {
    handleHeaderCart();
    handleHeaderCartMobile();
  }
});

//fill the cart in the header with vue
var headercart;
function handleHeaderCart() {
  var headercart = createApp({
    data() {
      return {
        cart: {},
        dropdown_init: 1,
        dropdown_active: 0,
        dropdown_hide_timeout: 0,
      };
    },
    methods: {
      getData() {
        clearTimeout(this.dropdown_hide_timeout);

        const vm = this;
        this.$http.get("/ajax/cart/last-items")
          .then(function (response) {
            vm.cart = response.data;
            EventBus.emit("updated-headercart");

            if (vm.dropdown_init == 0) {
              $("#headercart").addClass("show");
              $("[aria-labelledby='menu-cart']").addClass("show");

              vm.dropdown_hide_timeout = setTimeout(function () {
                $("#headercart").removeClass("show");
                $("[aria-labelledby='menu-cart']").removeClass("show");
                self.dropdown_active = 0;
              }, 2000);
            }

            vm.dropdown_init = 0;
          }).catch(error => {
            if(error.response !== undefined && error.response.status !== undefined && error.response.status == 403) {
              location.reload();
            } else {
              Sentry.captureException(error);
            }
          });
      },
      parseEntities: function (string) {
        var txt = document.createElement("textarea");
        txt.innerHTML = string;
        return txt.value;
      },
    },
    created() {
      this.getData();
    },
    mounted() {
      EventBus.on("update-headercart", () => this.getData());
    },
  })
    .use(VueAxios, axios)
    .mount("#headercart");
}

//fill the cart in the header with vue
var headercartmobile;
function handleHeaderCartMobile() {
  var headercartmobile = createApp({
    data() {
      return {
        cart: {},
      };
    },
    methods: {
      getData: function () {
        const vm = this;
        this.$http.get("/ajax/cart/last-items")
          .then(function (response) {
            vm.cart = response.data;
          })
          .catch(error => {
            console.log(error.response.data.error)
          });
        // this.cart = headercart.cart;
      },
    },
    created() {},
    mounted() {
      EventBus.on("update-headercart", () => this.getData());
    },
  })
    .use(VueAxios, axios)
    .mount("#headercartmobile");
}

//do this stuff on init AND on resize
function doOnResize() {
  //fix height of titles
  var heighest_block = 0;
  $("h5.product__title").css("height", "auto");
  $("h5.product__title").each(function () {
    if ($(this).innerHeight() > heighest_block) {
      heighest_block = $(this).innerHeight();
    }
  });

  $("h5.product__title").css("height", heighest_block);

  //fix height of prices
  var heighest_block = 0;
  $(".product__price").css("height", "auto");
  $(".product__price").each(function () {
    if ($(this).innerHeight() > heighest_block) {
      heighest_block = $(this).innerHeight();
    }
  });

  $(".product__price").css("height", heighest_block);

  if ($(window).width() < 786) {
    $("#grid").click();
  }
}
